<template>
  <p>
    <strong>Как выдавать курсы и тесты?</strong> &ndash; курсы и тесты можно
    выдавать только через группы т.е. вам необходимо будет создать группы,
    добавить в эти группы сотрудников, назначить кураторов, выдать квоты на
    тесты и/или курсы во вкладке &laquo;Работа с сотрудниками&raquo; и затем
    распределить все между сотрудниками.
  </p>
  <p>
    <strong>Как добавить сотрудников в группу?</strong> &ndash; это можно
    сделать, загрузив список сотрудников через excel, или добавить сотрудников
    по одному, заполняя все поля вручную, а потом добавить их в нужную группу по
    кнопке &laquo;Редактировать&raquo;. Добавленные пользователи автоматически
    получают на свою почту письма с регистрационными данными, по которым они
    смогут зайти в свои личные кабинеты.
  </p>
  <p>
    <strong>Кто такие кураторы?</strong> &ndash; эта роль, которая выдается
    сотрудникам, распределяющим курсы и тесты в группах. Для того, чтобы
    назначить куратора, необходимо конкретному сотруднику поменять роль с
    &laquo;сотрудника&raquo; на &laquo;куратора&raquo; и в конкретной группе по
    кнопке &laquo;Редактировать&raquo; отметить его в поле
    &laquo;Куратор&raquo;. После этого данный сотрудник сможет распределять
    выданные квоты на тесты и/или курсы.
  </p>
  <p><strong>Где проходить курсы?</strong> &ndash; во вкладке курсы.</p>
  <p>
    <strong>Что такое квоты на тестирование, на курсы?</strong> &ndash; единица
    товара, предоставляющая доступ одному пользователю пройти тестирование (если
    квота на тест(отчет) или пройти курс (если квота на курс). Работа с квотами
    осуществляется только в группах. Квоту на тест или на курс можно забрать у
    сотрудника и передать другому, только если первый сотрудник не приступал к
    тесту/курсам
  </p>
  <p>
    <strong>Где посмотреть результаты тестирования сотрудников?</strong> &ndash;
    Результаты тестирования это отчёт. Отчёты с результатами сотрудников
    находятся в группе. В списке участников группы есть значок результатов
    тестирования, на который необходимо нажать, чтобы увидеть результаты нужного
    сотрудника.
  </p>

  <h3>Вкладки</h3>
  <p>
    <strong>Аналитика</strong> &ndash; вкладка, в которой можно посмотреть
    аналитику по каждой отдельной группе, сопоставить средние результаты по
    тесту двух групп между собой, наблюдать динамику изменений уровня
    эмоционального интеллекта, а также оценивать эффективность обучения и
    внедрения среды путем сравнения результатов тестирования &laquo;до&raquo; и
    &laquo;после&raquo; развивающих программ. Можно загружать эталонные
    ЭИ-профили идеальных сотрудников на ту или иную должность и смотреть, кто
    полностью соответствует, у кого есть потенциал к росту, и кто может быть
    более эффективным на другой позиции.
  </p>
  <p>
    <strong>Компания</strong> &ndash; вкладка, в которой представлена информация
    о компании
  </p>
  <p>
    <strong>Сотрудники</strong> &ndash; вкладка, в которой представлен список
    сотрудников
  </p>
  <p>
    <strong>Кураторы</strong> &ndash; вкладка, в которой представлен список
    сотрудников с правами &laquo;куратор&raquo;.
  </p>
  <p>
    <strong>Работа с сотрудниками</strong> &ndash; вкладка, в которой
    представлен список групп, выдавать курсы и тесты можно только через группы
  </p>
  <p>
    <strong>Заказы</strong> &ndash; вкладка, в которой представлен список
    заказов. Заказы могут делать только компании, для использования продуктов в
    пределах компании или частные лица для личного использования
  </p>

  <h3>Статусы тестирования</h3>
  <div class="img-description">
    <img src="./../../assets/images/icons/clock1.svg" />
    <div>
      - <strong>«Тест не выдан»</strong> - тест пользователю не назначен
    </div>
  </div>
  <div class="img-description">
    <img src="./../../assets/images/icons/clock2.svg" />
    <div>
      - <strong>«Тест не пройден»</strong> - пользователю выдан доступ к
      прохождению тестирования, но он еще не приступал к его прохождению. (На
      этом этапе еще можно забрать квоту на тестирование у данного пользователя
      и передать ее другому)
    </div>
  </div>
  <div class="img-description">
    <img src="./../../assets/images/icons/clock3.svg" />
    <div>
      - <strong>«В процессе прохождения»</strong> - пользователю выдан доступ к
      прохождению тестирования, и он приступил к нему. (На этом этапе уже нельзя
      забрать квоту на тестирование у данного пользователя и передать ее
      кому-либо другому)
    </div>
  </div>
  <div class="img-description">
    <img src="./../../assets/images/icons/clock4.svg" />
    <div>
      - <strong>«Тест завершен»</strong> - пользователь полностью прошел
      тестирование, по его результатам сформирован отчет.
    </div>
  </div>

  <h3>Статусы обучения</h3>
  <div class="img-description">
    <i class="fal fa-clipboard"></i>
    <div>- Нет назначенных курсов или тренажеров</div>
  </div>
  <div class="img-description">
    <i class="fal fa-clipboard-check"></i>
    <div>- Назначены курсы или тренажеры</div>
  </div>

  <h3>Работа с сотрудниками</h3>
  <a
    class="btn btn-accent staff-link"
    :href="`${url}/uploads/pdf/course_instruction.pdf`"
    target="_blank"
    >Скачать инструкцию для работы с сотрудниками</a
  >
</template>

<script>
export default {
  name: "directorFAQ",
  setup() {
    const url = process.env.VUE_APP_FRONT;

    return { url };
  },
};
</script>

<style lang="scss" scoped>
@import "./../../assets/css/faq";
</style>